import Imo from "../../images/imo.png"

const areas = [
  {
    area: "Direito Previdenciário",
    desc:
      "O direito previdenciário atua na busca de benefícios para os segurando do Instituto Nacional de Seguridade Social-INSS,aposentadorias, auxilios, pensao por morte, revisões de beneficios entre outras demandas.",
    img: Imo,
  },
]

export default areas
